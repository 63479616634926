import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollWithWheelDirective } from './directive/scroll-with-wheel/scroll-with-wheel.directive';
import { ScrollButtonComponent } from './component/scroll-button/scroll-button.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ScrollWithWheelDirective, ScrollButtonComponent],
  imports: [CommonModule, MatIconModule],
  exports: [ScrollWithWheelDirective],
})
export class ScrollWithWheelModule {}
