<div
  (mousedown)="onMousedown()"
  (mouseup)="onMouseup()"
  (touchend)="onMouseup()"
  (touchstart)="onMousedown()"
  [ngClass]="mode"
  [style.--solid-color]="solidColor"
  class="scroll-button">
  @if (mode === 'left') {
    <mat-icon fontSet="material-symbols-outlined" class="icon"> chevron_left</mat-icon>
  }

  @if (mode === 'right') {
    <mat-icon fontSet="material-symbols-outlined" class="icon"> chevron_right</mat-icon>
  }
</div>
