import { Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-scroll-button',
  templateUrl: './scroll-button.component.html',
  styleUrls: ['./scroll-button.component.scss'],
})
export class ScrollButtonComponent implements OnDestroy {
  @Input() public mode: 'left' | 'right' | 'top' | 'bottom' = 'left';
  @Input() public solidColor = 'white';
  private clickSub?: Subscription;

  constructor(private element: ElementRef) {}

  ngOnDestroy(): void {
    this.clickSub?.unsubscribe();
  }

  onMousedown(): void {
    if (this.mode === 'top' || this.mode === 'bottom') {
      return;
    }
    this.clickSub = interval(50).subscribe(() => {
      const event = document.createEvent('MouseEvent');
      event.initEvent('wheel', true, true);
      (
        event as unknown as {
          deltaY: number;
        }
      ).deltaY = this.mode === 'left' ? Number.NEGATIVE_INFINITY : Number.MAX_VALUE;
      this.element.nativeElement.dispatchEvent(event);
    });
  }

  onMouseup(): void {
    this.clickSub?.unsubscribe();
  }
}
